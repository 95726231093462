import { RedocStandalone } from "redoc";
import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Loading } from "../components";
// eslint-disable-next-line import/no-unresolved
import definition from "../definitions/cip/openapi.v1.complete.yaml";

const CipRedocStandalone = () => (
  <RedocStandalone
    specUrl={
      process.env.NODE_ENV === "production"
        ? definition
        : `${process.env.PUBLIC_URL}/definitions/cip/openapi.v1.yaml`
    }
    options={{
      schemaExpansionLevel: "all",
      jsonSampleExpandLevel: "all",
      requiredPropsFirst: true,
      theme: {
        menu: {
          width: "360px",
        },
        colors: {
          primary: {
            main: "#2196F3",
            dark: "#0B79D0",
            light: "#64B6F7",
          },
          secondary: {
            main: "#E91E63",
            dark: "#BE134D",
            light: "#F06191",
          },
          info: {
            main: "#2196F3",
            dark: "#0B79D0",
            light: "#64B6F7",
          },
        },
      },
    }}
  />
);

export default withAuthenticationRequired(CipRedocStandalone, {
  onRedirecting: () => <Loading />,
});
