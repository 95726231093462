import React from "react";
import { Routes, Route } from "react-router-dom";

import { withAuth0 } from "@auth0/auth0-react";

import { CipRedocStandalone } from "./views";

import "./App.css";

const App = () => (
  <div id="app" className="d-flex flex-column h-100">
    <div className="container flex-grow-1">
      <div className="mt-5">
        <Routes>
          <Route path="/" element={<CipRedocStandalone />} />
        </Routes>
      </div>
    </div>
  </div>
);

export default withAuth0(App);
